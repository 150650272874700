import { isServerSide, clone } from '#helpers/index.js';
import { CONNECTED_COMPONENTS } from '#components/web-component.js';

// TODO: move to own file
export const INITIAL_STATE = {
	locale: 'en-US',
	isNetworkOnline: true,
	dialogs: [], // use stack? new Stack()
	notifications: [],
	replacer: function (key, value) {
		if (value instanceof Map || value instanceof Set) {
			return [...value];
		} else if (this[key] instanceof Date) {
			// Date.prototype.toJSON is native that auto-converts to a string by the time this is called
			return this[key].getTime();
		} else if (key === 'dialogs' || key === 'notifications') {
			return [];
		}

		return value;
	},
	reviver: function (key, value) {
		switch (key) {
			case 'date':
				return new Date(value);
			case 'map':
				return new Map(value);
			case 'set':
				return new Set(value);
			default:
				return value;
		}
	}
}

export function StateHistory(context) {
	this.context = context;
	this.items = [];
	return this;
}

Object.defineProperties(StateHistory.prototype, {
	push: {
		value: function (path, newValue, oldValue) {
			if (globalThis.globalState.env !== 'production') {
				console.log('STATE:', this.context === window ? 'global' : this.context, `${path} updated from:`, oldValue, 'to', newValue);
			}

			this.items.push(new StateEntry(path, oldValue, this.context));
		}
	},
	pop: {
		value: function () {
			const item = this.items.pop();

		}
	},
	clear: {
		value: function () {
			this.items.length = 0;
		}
	}
});

function StateEntry(path, prevValue, context) {
	this.path = path;
	this.prevValue = prevValue;
	this.context = context;
	return this;
}

Object.defineProperties(StateEntry.prototype, {
	revert: {
		value: function () {
			const key = this.path.at(-1);
			const context = this.context === globalThis ? globalState : this.context.props;

			// get deep nested reference object
			let ref = this.path.slice(0, -1).reduce((acc, key) => {
				return acc[key];
			}, context);

			ref[key] = this.prevValue;
		}
	}
});

// TODO: don't hard-code?
const ARRAY_MUTATING_METHODS = ['push', 'unshift', 'shift', 'pop', 'splice', 'fill', 'sort', 'reverse', 'flat', 'copyWithin'];
const MAP_MUTATING_METHODS = ['set', 'delete'];
const SET_MUTATING_METHODS = ['add', 'remove'];
const DATE_MUTATING_METHODS = ['setDate', 'setFullYear', 'setHours', 'setMilliseconds', 'setMinutes', 'setMonth', 'setSeconds', 'setTime', 'setUTCDate', 'setUTCFullYear', 'setUTCHours', 'setUTCMilliseconds', 'setUTCMinutes', 'setUTCMonth', 'setUTCSeconds'];
const MUTATING_METHODS = new Set([...ARRAY_MUTATING_METHODS, ...MAP_MUTATING_METHODS, ...SET_MUTATING_METHODS, ...DATE_MUTATING_METHODS]);

const handler = {
	getOwnPropertyDescriptor(target, prop) {
		if (prop === '[[Handler]]') {
			return { configurable: true, enumerable: true, value: this };
		} else if (prop === '[[Target]]') {
			return { configurable: true, enumerable: true, value: target };
		}

		return Object.getOwnPropertyDescriptor(target, prop);
	},
	get: function (target, prop) {
		if (prop === 'toJSON') {
			return function () {
				return target;
			};
		}

		let getValue = Reflect.get(target, prop);

		if (typeof getValue === 'function') {
			const rootPath = this.path.split('.');
			const key = rootPath[1];
			const handler = this;

			return function (key, ...args) {
				const isMutatingMethod = MUTATING_METHODS.has(prop);

				let clonedValue;
				if (isMutatingMethod) {
					clonedValue = clone(target);
				}

				const returnValue = getValue.apply(this, args);

				if (isMutatingMethod) {
					handler.stateHistory.push(rootPath.slice(1), this, clonedValue);
					handler.render(key);
				};

				return returnValue;
			}.bind(target, key);
		}

		return getValue;
	},
	set: function (target, prop, value) {
		const path = `${this.path}.${prop}`;
		const curValue = target[prop];
		const setValue = typeof value === 'object' ? proxify(value, this.context, path) : value;
		const rootPath = path.split('.');
		const key = rootPath[1];
		const ret = Reflect.set(target, prop, setValue);

		if (curValue !== setValue) {
			this.stateHistory.push(rootPath.slice(1), setValue, curValue);
			this.render(key);
		}

		return ret;
	},
	deleteProperty: function (target, prop) {
		const path = `${this.path}.${prop}`;
		const curValue = target[prop];
		const rootPath = path.split('.');
		const key = rootPath[1];

		if (prop in target) {
			delete target[prop];
		}

		this.stateHistory.push(rootPath.slice(1), undefined, curValue);
		this.render(key);
	},
	render: function (key) {
		if (this.context === window) {
			CONNECTED_COMPONENTS.forEach(component => {
				if (component.globalSubscriptions.has(key)) {
					component.render();
				}
			});
		} else {
			this.context.render();
		}
	}
}

if (!isServerSide) {
	const fromServer = document.getElementById('global-state');
	const state = fromServer ? JSON.parse(fromServer.textContent, INITIAL_STATE.reviver) : INITIAL_STATE;

	window.globalState = createState(state, window);
	window.globalStateHistory = new StateHistory(window);

	const components = document.getElementById('component-state');
	if (components) {
		window.components = JSON.parse(components.textContent);
	}
}

function proxify(state, context, path = 'root') {
	if (typeof state !== 'object') {
		throw new TypeError('Cannot create proxy with a non-object as target');
	}

	const boundHandler = Object.create(handler, {
		path: { value: path },
		context: { value: context },
		stateHistory: {
			get: function () {
				return this.context === globalThis ? globalThis.globalStateHistory : this.context.stateHistory;
			}
		},
	});

	let proxy;

	if (Array.isArray(state) || state instanceof Map || state instanceof Set) {
		proxy = state;
	} else {
		proxy = {};

		for (const key in state) {
			const value = state[key];
			const pathKey = `${path}.${key}`;
			const nestedHandler = Object.create(boundHandler, {
				path: { value: pathKey },
			});

			let newValue = value;

			// null or undefined can't be proxied
			if (!value) {
				continue;
			}

			if (Object.getPrototypeOf(value) === Object.prototype) {
				newValue = proxify(value, context, pathKey);
			} else if (typeof value === 'object') {
				newValue = new Proxy(newValue, nestedHandler);
			}

			proxy[key] = newValue;
		}
	}

	return new Proxy(proxy, boundHandler);
}

const BASE_STATE = {
	toJSON: function () {
		const props = {};
		for (const key in this) {
			props[key] = this[key];
		}
		return props;
	}
}

export function createState(state, context, path) {
	if (isServerSide) {
		return Object.create(BASE_STATE, Object.getOwnPropertyDescriptors(state));
	}

	return proxify(state, context, path);
}
