import WebComponent from '#components/web-component.js';
import Message from '#components/message.js';

function handleSubmit(e) {
	e.preventDefault();

	if (!this.classList.contains('submission-attempt')) {
		this.classList.add('submission-attempt');
	}

	if (!this.checkValidity()) {
		const firstInvalid = this.querySelector(':invalid');
		firstInvalid?.focus();
		return;
	}

	this.props.submissionState = SUBMISSION_STATE.SUBMITTING;

	const formData = new FormData(this);
	const form = this;

	const { name, value } = e.submitter ?? {};
	if (name && value) {
		formData.set(name, value);
	}

	if (this.method === 'get') {
		const url = new URL(this.action);
		const params = new URLSearchParams(formData);

		for (const param of params.entries()) {
			url.searchParams.set(param[0], param[1]);
		}

		window.bfCache.get(url.toString());
	} else if (this.method === 'post') {
		const xhr = new XMLHttpRequest();

		xhr.onload = function (e) {
			if (this.response.ok) {
				form.dispatchEvent(new CustomEvent('success', { detail: formData, bubbles: true }));
				form.props.submissionState = SUBMISSION_STATE.SUCCESS;
			} else {
				form.dispatchEvent(new CustomEvent('error', { detail: formData, bubbles: true }));
				form.props.submissionState = SUBMISSION_STATE.ERROR;
			}
		};

		xhr.onerror = function (e) {
			form.props.submissionState = SUBMISSION_STATE.ERROR;
		};

		xhr.open('POST', form.action);
		xhr.responseType = 'json';
		xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
		xhr.send(formData);
	}
}

function handleReset(e) {
	this.classList.remove('submission-attempt');
	this.props.submissionState = SUBMISSION_STATE.EDIT;
}

export const SUBMISSION_STATE = {
	'EDIT': 'edit',
	'SUBMITTING': 'submitting',
	'SUCCESS': 'success',
	'ERROR': 'error'
}

const Form = new WebComponent({
	tagName: 'form',
	defaultAttributes: {
		is: 'x-form'
	},
	defaultProps: {
		submissionAttempts: 0,
		successMessage: 'Form submitted successfully!',
		errorMessage: 'There was an error submitting your form',
		submissionState: SUBMISSION_STATE.EDIT,
	},
	mapPropsToAttrs: ['action', 'method', 'id', 'hidden'],
	connectedCallback: function () {
		this.noValidate = true;
		this.addEventListener('submit', handleSubmit);
		this.addEventListener('reset', handleReset);
	},
	disconnectedCallback: function () {
		this.reset();
		this.removeEventListener('submit', handleSubmit);
		this.removeEventListener('reset', handleReset);
	},
	render: function () {
		const { submitText, resetText, submissionState, successMessage, errorMessage } = this.props;
		const isSubmitting = submissionState === SUBMISSION_STATE.SUBMITTING;
		let message = null;

		if (submissionState === SUBMISSION_STATE.SUCCESS) {
			message = Message({ type: 'success', message: successMessage });
		} else if (submissionState === SUBMISSION_STATE.ERROR) {
			message = Message({ type: 'error', message: errorMessage });
		}

		return html`
			${this.content}

			<menu>
				${submitText ? html`<li><button type="submit" disabled="${isSubmitting}">${submitText}</button></li>` : ''}
				${submitText ? html`<li><button type="reset" disabled="${isSubmitting}">${resetText}</button></li>` : ''}
			</menu>

			${message}
		`;
	}
});

export default Form;
