import ViewComponent from '#components/view-component.js';
import Abbr from '#components/abbr.js';

const About = new ViewComponent({
	defaultAttributes: {
		is: 'x-page-about',
	},
	render: function () {
		return html`
			<main>
				<hgroup>
					<h1>About</h1>
					<p>Who is this person?</p>
				</hgroup>

				<section>
					<h2>${Abbr({ title: 'Too Long; Didn\'t Read', text: 'tl;dr' })}</h2>
					<p>I'm Kevin Slodic (he/him/his); a front-end engineering leader with over ${new Date().getFullYear() - 2007} years of professional experience based out of Las Vegas, NV.</p>
					<p>I'm creative; have an eye for design; am collaborative &amp; communicative; and am passionate about web standards, accessibility, and performance.</p>
				</section>

				<section>
					<h2>Humble Beginnings</h2>
					<p>It all started as a hobby designing and building friends' bands' webpages using simple HTML and an overabundance of the &lt;font&gt; tag. Who knew that it'd turn into a career in web development/engineering.</p>

					<h3>The Rubber City</h3>
					<p>I officially kicked off my foray into the programming world with a stint at the University of Akron studying Computer Science. I tackled courses like Discrete Mathematics and Intro. to Computer Science with my trusty <a href="https://www.amazon.com/How-Program-4th-Harvey-Deitel/dp/0130384747">Deitel - C++ How to Program Fourth Edition</a>&mdash;which I still have to this day.</p>
					<p>Building and designing for the ${Abbr({ title: 'World Wide Web', text: 'WWW' })} was still relatively new and there wasn't much coursework focused on web development, so I felt I needed to change things up to hone in on my passion.</p>

					<h3>The Tree City</h3>
					<p>I decided to transfer to Kent State University as they offered a larger variety of web-oriented curriculum. I tackled such coursework as: Web Database Integration, Web Scripting, Digital Imaging, and Advanced Flash.</p>
					<p>I finished my degree a semester early in December 2006 graduating summa cum laude with a Bachelor of Science degree in Technology.</p>

					<h3>Semi-Pro</h3>
					<p>Whilst still in school, I ended up working for a local Cleveland, Ohio bank (i.e. Park View Federal Savings Back, now bought by First National) as a web developer creating Flash Ads, building functionality on their intranet portal, and contributing to an ${Abbr({ title: 'Automated Clearing House', text: 'ACH' })} application.</p>
				</section>

				<section>
					<h2>Going Pro</h2>

					<h3>Cleaning Crew</h3>
					<p>I started my career with TTI Floor Care North America&mdash;the parent company of the Dirt Devil, Hoover, and Oreck vacuum cleaner brands&mdash;as a Web Application Programmer. For 8.5 years, I developed numerous applications including:</p>
					<ul>
						<li>The ecommerce shopping cart</li>
						<li>The dirtdevil.com and hoover.com ecommerce websites</li>
						<li>The internal product information management system (a.k.a. PIM)</li>
						<li>A digital asset management system</li>
						<li>An ecommerce dashboard reporting application</li>
						<li>The internal CRM</li>
					</ul>

					<h3>Commerce, Customer Service, Company Culture, and Community</h3>
					<p>Needing a new challenge, I joined Zappos.com as a Senior Front-end Developer. For 2 years, I worked primarily on the top funnel experiences: home, landing, search, and product detail pages as well as the header and footer.</p>
					<p>Wanting to expand my technical skillset, I transitioned into an engineering manager and for 4.5 years I managed a cross-functional group of engineers (front-end, back-end, and QA) and UX designers building the top funnel experiences.</p>

					<h3>Quit Slackin'</h3>
					<p>Wanting to get back into ${Abbr({ title: 'Individual Contributor', text: 'IC' })} work, I joined Slack (a Salesforce company) as a Staff Front-end Engineer. I worked for the Adoption team managing the invite experience and contributed to projects including:</p>
					<ul>
						<li>Accept in Slack</li>
						<li>Invite Suggestions</li>
						<li>Connectable Contacts</li>
					</ul>

					<h3>A New Door</h3>
					<p>An opportunity with Opendoor presented itself and I currently work as a Staff Front-end Engineer on the Open Exchange team building and maintaing an app that brokers transactions between Real Estate Investment Trusts (REITs).</p>
				</section>
			</main>

			<aside>
				<section>
					<h2>Resume</h2>
					<ul class="image-list">
						<li><a href="/downloads/resume.pdf" target="_blank"><img src="/images/pdf.svg" alt="PDF"></a></li>
					</ul>
				</section>

				<section>
					<h2>Tech</h2>
					<ul class="blocked">
						<li>HTML</li>
						<li>CSS</li>
						<li>JavaScript</li>
						<li>TypeScript</li>
						<li>React</li>
						<li>Redux</li>
						<li>SASS/SCSS</li>
						<li>LESS</li>
						<li>.NET</li>
						<li>PHP/Hack</li>
						<li>SQL</li>
					</ul>
				</section>
			</aside>`;
	}
});

export default About;
