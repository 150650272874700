import { isNullOrUndefined, isServerSide, areEqual } from '#helpers/index.js';

export const PLACEHOLDER_ATTR = '{{attr-node}}';
export const FRAGMENT_START = '<!--<>-->';
export const FRAGMENT_END = '<!--</>-->';
export const FRAGMENT_CONTENT_START = '<!--<content>-->';
export const FRAGMENT_CONTENT_END = '<!--</content>-->';

// convert HTML string variants above to nodes
export let FRAGMENT_START_ELEMENT;
export let FRAGMENT_END_ELEMENT;
export let FRAGMENT_CONTENT_START_ELEMENT;
export let FRAGMENT_CONTENT_END_ELEMENT;
export let FRAGMENT_ELEMENTS = new Set();

const HTML_PART_CHILD = 'child';
const HTML_PART_ATTRIBUTE = 'attribute';
const HTML_PART_PARTIAL_ATTRIBUTE = 'partial-attribute';
const HTML_PART_TITLE = 'title';
export const HTML_PART_ATTRIBUTE_SET = 'attribute-set';
export const ATTRIBUTE_NODES = new Set([HTML_PART_ATTRIBUTE_SET, HTML_PART_ATTRIBUTE, HTML_PART_PARTIAL_ATTRIBUTE]);

const BLANK_PNG_IMAGE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYPhfDwAChwGA60e6kgAAAABJRU5ErkJggg==';

// convert HTML to Element
if (!isServerSide) {
	const container = document.createElement('div');

	[FRAGMENT_START_ELEMENT, FRAGMENT_END_ELEMENT, FRAGMENT_CONTENT_START_ELEMENT, FRAGMENT_CONTENT_END_ELEMENT] = [FRAGMENT_START, FRAGMENT_END, FRAGMENT_CONTENT_START, FRAGMENT_CONTENT_END].map(function (elementHtml) {
		container.insertAdjacentHTML('beforeend', elementHtml);
		const element = container.lastChild;
		FRAGMENT_ELEMENTS.add(element);
		return container.removeChild(element);
	});

	window.html = htmlClient;
}

const BOOLEAN_ATTRIBUTES = new Set([
	'allowfullscreen',
	'allowpaymentrequest',
	'async',
	'autofocus',
	'autoplay',
	'checked',
	'controls',
	'default',
	'disabled',
	'formnovalidate',
	'hidden',
	'ismap',
	'itemscope',
	'loop',
	'multiple',
	'muted',
	'nomodule',
	'novalidate',
	'open',
	'playsinline',
	'readonly',
	'required',
	'reversed',
	'selected',
	'truespeed'
]);

const setAttribute = (attr, value, tagName) => {
	const isBoolAttr = attr.startsWith('?');
	attr = attr.substring(isBoolAttr ? 1 : 0);
	if (isBoolAttr || BOOLEAN_ATTRIBUTES.has(attr)) {
		// e.g. { attr: 'checked', value: 'checked' } || { attr: 'checked', value: true }
		return attr === value || value === true ? attr : '';
	} else if (tagName === 'textarea' && attr === 'value') {
		return '';
	} else if (attr !== 'alt' && attr !== 'value' && value === '') {
		return '';
	} else if (attr === 'class' && Array.isArray(value)) {
		return `${attr}="${value.join(' ')}"`;
	} else if (typeof value === 'object' && value.toString === Object.prototype.toString) {
		return `${attr}="${JSON.stringifyForHtml(value)}"`;
	} else if (typeof value === 'string') {
		return `${attr}="${value.replace(/"/g, '&quot;')}"`;
	} else {
		return `${attr}="${value}"`;
	}
};

function HtmlPieces(items) {
	this.items = items;
	return this;
}

Object.defineProperties(HtmlPieces.prototype, {
	toString: {
		value: function () {
			return this.items.map(item => item.toString(this.isWebComponent, this.state, this.components)).join('').trim();
		}
	}
});

function HtmlPiece(prefix, value, isAttr, shouldWrapInFrag) {
	this.prefix = prefix;
	this.value = value;
	this.isAttr = !!isAttr;
	this.shouldWrapInFrag = !!shouldWrapInFrag;
	return this;
}

Object.defineProperties(HtmlPiece.prototype, {
	toString: {
		value: function (isWebComponent, state, components) {
			let returnValue = this.value;

			if (Array.isArray(this.value)) {
				returnValue = this.value = this.value.reduce((acc, value) => {
					let returnValue = value;

					if (value.isWebComponent) {
						returnValue = value.render(state, components);
					} else if (value instanceof HtmlPieces) {
						value.state = state;
						value.components = components;
						returnValue = value.toString();
					}

					return acc + returnValue;
				}, '');
			} else if (returnValue.isWebComponent) {
				returnValue = this.value = this.value.render(state, components)
			} else if (typeof returnValue === 'function') {
				returnValue = returnValue();
			}

			return this.prefix + (isWebComponent && this.shouldWrapInFrag && !this.isAttr ? `${FRAGMENT_START}${returnValue}${FRAGMENT_END}` : returnValue);
		}
	}
});

const NEVER_WRAP_IN_FRAGS = new Set(['textarea', 'script', 'style', 'title']);

function htmlServer(strings, ...args) {
	let shouldStripFirstChar = false; // to strip initial '"' char
	let isAttr = false;
	let currentTagName;

	let html = strings.map((string, index, arr) => {
		const value = args[index] ?? '';
		let aggregateValue;

		({ currentTagName, isAttr } = getHtmlPosition(string, isAttr, currentTagName));

		if (shouldStripFirstChar) {
			string = string.substring(1);
			shouldStripFirstChar = false;
		}

		const attrs = isAttr && string.split(' '); // <img src="/img.svg" alt="${'Alt Text'}"> -> ['<img', 'src="/img.svg"', 'alt="']
		const lastAttrName = isAttr && attrs.at(-1).endsWith('="') && arr[index + 1].startsWith('"') ? attrs.pop().slice(0, -2) : null; // e.g. name="${value}" -> name

		if (lastAttrName) {
			shouldStripFirstChar = true;
			const attr = setAttribute(lastAttrName, value);
			if (attr) {
				attrs.push(attr);
			}

			return new HtmlPiece('', attrs.join(' '));
		} else if (Array.isArray(value)) {
			aggregateValue = value.filter(v => v ?? false);
		} else if (typeof value === 'object') {
			// TODO: should this stringify or just return the object? see toString in webComponent.js#115
			aggregateValue = !isAttr ? value : Object.entries(value).reduce((acc, [key, value]) => {
				const output = setAttribute(key, value, currentTagName);

				if (output) {
					acc.push(output);
				}

				return acc;
			}, []).join(' ');
		} else {
			aggregateValue = value;
		}

		return new HtmlPiece(string, aggregateValue, isAttr, index < arr.length - 1 && !NEVER_WRAP_IN_FRAGS.has(currentTagName));
	});

	return new HtmlPieces(html);
}

export function htmlClient(strings, ...values) {
	let htmlTemplate = TEMPLATE_CACHE.get(strings);

	if (!htmlTemplate) {
		htmlTemplate = new HtmlTemplate(strings);
		TEMPLATE_CACHE.set(strings, htmlTemplate);
	}

	const { template } = htmlTemplate;
	let clone;

	if (template.nodeName === 'HTML') {
		const frag = document.createDocumentFragment();
		let el = template.firstChild;
		do {
			frag.appendChild(el.cloneNode(true));
		} while (el = el.nextSibling)
		clone = frag;
	} else {
		clone = document.importNode(template.content, true);
	}

	const placeholders = HtmlTemplate.createPlaceholders(clone);

	return new HtmlTemplateResult(clone, values, placeholders);
}

export default function html() {
	return isServerSide ? htmlServer.apply(this, arguments) : htmlClient.apply(this, arguments);
}

function getHtmlPosition(string, isAttr, currentTagName) {
	const hasSpace = string.includes(' ');
	let start = string.lastIndexOf('<');
	let end = string.lastIndexOf('>');

	// nearest > is another tag's closer; e.g. </p><p class="
	if (start !== -1 && end !== -1 && end < start) {
		end = -1;
	}

	// closing tag
	if (isAttr && end !== -1) {
		isAttr = false;
	}

	// in a tag for attributes; e.g. <p class="
	if (start > -1 && end === -1) {
		isAttr = true;
		currentTagName = string.substring(start + 1, hasSpace ? string.indexOf(' ', start + 1) : end);
	}

	// <p>
	if (start > -1 && end > start) {
		currentTagName = string.substring(start + 1, hasSpace ? string.indexOf(' ', start + 1) : end);
	}

	return { isAttr, currentTagName };
}

const TEMPLATE_CACHE = new WeakMap();

function HtmlTemplate(strings) {
	const isHtmlDoc = strings[0].startsWith('<head>');
	const template = isHtmlDoc ? document.implementation.createHTMLDocument().documentElement : document.createElement('template');
	let isAttr = false;
	let currentTagName;

	template.innerHTML = strings.reduce(function (html, string, index, arr) {
		({ isAttr, currentTagName } = getHtmlPosition(string, isAttr, currentTagName));
		return html + string + (index < arr.length - 1 ? (isAttr ? PLACEHOLDER_ATTR : (NEVER_WRAP_IN_FRAGS.has(currentTagName) ? '' : FRAGMENT_START + FRAGMENT_END)) : '');
	}, '').trim();

	const source = isHtmlDoc ? template : template.content;

	// TODO: implement img[srcset] and account for an element that has 1+ of these attrs
	source.querySelectorAll(`img[src*="${PLACEHOLDER_ATTR}"], link[href*="${PLACEHOLDER_ATTR}"], iframe[src*="${PLACEHOLDER_ATTR}"]`).forEach(function (el) {
		let attr;

		if (el.src) {
			attr = 'src';
		} else if (el.href) {
			attr = 'href';
		}

		const value = el.getAttribute(attr);
		el.dataset.placeholderAttr = attr;
		el.dataset.placeholderType = value !== PLACEHOLDER_ATTR ? HTML_PART_PARTIAL_ATTRIBUTE : HTML_PART_ATTRIBUTE;
		el.dataset.placeholderAttrValue = value;
		el[attr] = BLANK_PNG_IMAGE;
	})

	this.template = template;

	return this;
}

Object.defineProperties(HtmlTemplate, {
	render: {
		value: function (html, parent) {
			(parent || container).appendChild(html, container);
		}
	},
	createPlaceholders: {
		value: function (template) {
			const walker = document.createTreeWalker(template);
			const placeholders = new HtmlPlaceholderList();
			let index = -1; // since starting at DocumentFragment root

			do {
				const { currentNode } = walker;

				switch (currentNode.nodeType) {
					// search all attributes for a placeholder
					case 1: // element
						if (currentNode.nodeName === 'TITLE') {
							placeholders.push(new HtmlPlaceholder(HTML_PART_TITLE, currentNode, index));
						} else if (currentNode.dataset.placeholderAttr) {
							const attr = currentNode.attributes[currentNode.dataset.placeholderAttr];
							if (currentNode.dataset.placeholderType === HTML_PART_ATTRIBUTE) {
								placeholders.push(new HtmlPlaceholder(HTML_PART_ATTRIBUTE, attr, index));
							} else {
								const partial = currentNode.dataset.placeholderAttrValue.split(PLACEHOLDER_ATTR);
								for (let i = 0; i < partial.length - 1; ++i) {
									placeholders.push(new HtmlPlaceholder(HTML_PART_PARTIAL_ATTRIBUTE, attr, index, currentNode.dataset.placeholderAttrValue));
								}
							}

							delete currentNode.dataset.placeholderAttr;
							delete currentNode.dataset.placeholderType;
							delete currentNode.dataset.placeholderAttrValue;
						}

						for (let i = 0; i < currentNode.attributes.length; ++i) {
							const attribute = currentNode.attributes[i];
							const { name, value } = attribute;

							if (name === PLACEHOLDER_ATTR) {
								currentNode.removeAttributeNode(attribute);
								placeholders.push(new HtmlPlaceholder(HTML_PART_ATTRIBUTE_SET, currentNode, index));
							} else if (value === PLACEHOLDER_ATTR) {
								placeholders.push(new HtmlPlaceholder(HTML_PART_ATTRIBUTE, attribute, index));
							} else if (value.includes(PLACEHOLDER_ATTR)) {
								const partial = value.split(PLACEHOLDER_ATTR);
								for (let i = 0; i < partial.length - 1; ++i) {
									placeholders.push(new HtmlPlaceholder(HTML_PART_PARTIAL_ATTRIBUTE, attribute, index));
								}
							}
						}

						break;
					case 3: // text
					case 8: // comment
						if (currentNode.isEqualNode(FRAGMENT_START_ELEMENT)) {
							placeholders.push(new HtmlPlaceholder(HTML_PART_CHILD, currentNode, index));
						}
					default:
						break;
				}

				// ignore "closing" fragment
				if (!currentNode.isEqualNode(FRAGMENT_END_ELEMENT)) {
					index++;
				}
			} while (walker.nextNode());

			return placeholders;
		}
	}
});

function HtmlTemplateResult(clone, values, placeholders) {
	this.clone = clone;
	this.values = values;
	this.placeholders = placeholders;
	return this;
}

Object.defineProperties(HtmlTemplateResult.prototype, {
	isEqual: {
		value: function (templateResult) {
			if (!(templateResult instanceof HtmlTemplateResult)) {
				return false;
			}

			// TODO: leverage areEqual
			const areValuesEqual = this.values.length === templateResult.values.length && this.values.every((value, index) => {
				const templateValue = templateResult.values[index];

				if (value instanceof HtmlTemplateResult) {
					return value.isEqual(templateValue);
				} else if (value?.isWebComponent && templateValue?.isWebComponent) {
					return value.isEqual(templateValue);
				} else {
					return value === templateValue;
				}
			});

			return areValuesEqual;
		}
	}
});

function HtmlPlaceholderList() {
	this.items = [];
	return this;
}

Object.defineProperties(HtmlPlaceholderList.prototype, {
	push: {
		value: function (item) {
			return this.items.push(item);
		}
	},
	update: {
		value: function (resultContext, componentContext) {
			// render full component

			this.items.forEach(function (item, index, arr) {
				item.update(item, index, arr, this);
			}, { resultContext, componentContext });
		}
	}
});

const shadowedNames = {
	'for': 'htmlFor',
	'class': 'className',
	'tabindex': 'tabIndex'
};

function HtmlPlaceholder(type, node, index, overrideValue) {
	this.type = type;
	this.index = index;
	this.prevValue; // for future use

	if (type === HTML_PART_ATTRIBUTE_SET) {
		this.part = node;
	} else if (ATTRIBUTE_NODES.has(type)) {
		this.part = node;
		this.name = shadowedNames[node.name] || node.name;
		this.value = overrideValue || node.value;
		this.ownerElement; // for future use
	} else if (type === HTML_PART_TITLE) {
		this.part = [];
	} else {
		this.part = [];
		this.fragStart = node;
		this.fragEnd = node.nextSibling;
		this.contentFragStart = null;
		this.contentFragEnd = null;
	}
}

Object.defineProperties(HtmlPlaceholder.prototype, {
	removeFrags: {
		value: function () {
			['contentFragStart', 'contentFragEnd', 'fragStart', 'fragEnd'].forEach(function (name) {
				const frag = this[name];
				if (frag) {
					frag.remove();
					this[name] = null;
				}
			}, this)
		}
	},
	updateNodes: {
		value: function (frag, componentContext) {
			const newNodes = frag instanceof DocumentFragment ? Array.from(frag.childNodes) : frag;

			// check part lengths
			const remainderNodes = this.part.length - newNodes.length;
			let prevNode = this.contentFragStart || this.fragStart;

			for (let i = 0; i < newNodes.length; ++i) {
				const newNode = newNodes[i];
				const oldNode = this.part[i];

				if (oldNode) {
					if (!newNode.isEqualNode(oldNode)) {
						oldNode.replaceWith(newNode);
						this.part[i] = newNode;
						prevNode = newNode;
					} else {
						prevNode = oldNode;
					}
				} else {
					prevNode.after(newNode);
					this.part[i] = newNode;
					prevNode = newNode;
				}
			}

			// remove remaining nodes from DOM and re-allocate part length
			if (remainderNodes > 0) {
				const { length } = this.part;
				for (let i = length - remainderNodes; i < length; ++i) {
					this.part[i].remove();
				}

				this.part.length = newNodes.length;
			}

			/*
			// remove previous nodes
			this.part.forEach(oldNode => {
				oldNode.remove();
			});

			// add new nodes
			(this.contentFragStart || this.fragStart).after(...newNodes);

			this.part = newNodes;
			*/

			// remove markers if not a WebComponent
			if (!componentContext) {
				this.removeFrags();
			}
		}
	},
	shouldUpdate: {
		value: function (newValue, prevValue) {
			let shouldUpdate = false;

			if (Array.isArray(newValue) && Array.isArray(prevValue)) {
				shouldUpdate = newValue.length !== prevValue.length || !newValue.every((item, index) => item instanceof HtmlTemplateResult ? item.isEqual(prevValue[index]) : item === prevValue[index]);
			} else if (newValue instanceof HtmlTemplateResult) {
				shouldUpdate = !newValue.isEqual(prevValue);
			} else if (isNullOrUndefined(newValue) && isNullOrUndefined(prevValue)) {
				shouldUpdate = false;
			} else if (newValue?.isWebComponent && prevValue?.isWebComponent) {
				shouldUpdate = !newValue.isEqual(prevValue);
			} else if (newValue instanceof Element && prevValue instanceof Element) {
				shouldUpdate = !newValue.isEqualNode(prevValue);
			} else {
				shouldUpdate = !areEqual(prevValue, newValue);
			}

			return shouldUpdate;
		}
	},
	update: {
		value: (function () {
			let partPlaceholder = null;

			return function (placeholder, index, arr, context) {
				const { resultContext, componentContext } = context;
				const newValue = resultContext.values[index];
				const prevValue = componentContext?.prevValues?.[index];
				const { type } = placeholder;

				if (type !== HTML_PART_PARTIAL_ATTRIBUTE && !this.shouldUpdate(newValue, prevValue)) {
					return;
				}

				if (type === HTML_PART_ATTRIBUTE) {
					// try DOM property first
					if (placeholder.part && placeholder.part.ownerElement && placeholder.name in placeholder.part.ownerElement) {
						placeholder.part.ownerElement[placeholder.name] = newValue;
						return;
					}

					if (isNullOrUndefined(newValue)) {
						if (placeholder.part && placeholder.part.ownerElement) {
							placeholder.ownerElement = placeholder.part.ownerElement;
							placeholder.part.ownerElement.removeAttributeNode(placeholder.part);
						}
					} else {
						if (!placeholder.part) {
							placeholder.part = document.createAttribute(placeholder.name);
						}

						placeholder.part.value = newValue;

						if (!placeholder.part.ownerElement) {
							placeholder.ownerElement.setAttributeNode(placeholder.part);
						};
					}
				} else if (type === HTML_PART_PARTIAL_ATTRIBUTE) {
					if (partPlaceholder === null) {
						partPlaceholder = placeholder.value;
					}

					partPlaceholder = partPlaceholder.replace(PLACEHOLDER_ATTR, newValue); // leverage `replace` only replacing the first found instance
					partPlaceholder = partPlaceholder.replace(encodeURIComponent(PLACEHOLDER_ATTR), newValue);

					const nextPlaceholder = arr[index + 1];
					if (!nextPlaceholder || nextPlaceholder.type !== HTML_PART_PARTIAL_ATTRIBUTE || nextPlaceholder.part.name !== placeholder.part.name) {
						if (this.shouldUpdate(partPlaceholder, placeholder.part.value)) {
							placeholder.part.value = partPlaceholder;
						}
						partPlaceholder = null;
					}
				} else if (type === HTML_PART_ATTRIBUTE_SET) {
					// get previous attrs
					const prevAttrs = Object.keys(prevValue || []);

					Object.entries(newValue).forEach(([name, value]) => {
						if (name in placeholder.part) {
							placeholder.part[name] = value;
						} else {
							placeholder.part.setAttribute(name, value);
						}
					});

					// remove attrs not in new set
					prevAttrs
						.filter(attr => !(attr in newValue))
						.forEach(attr => placeholder.part.removeAttribute(attr));
				} else if (type === HTML_PART_TITLE) {
					document.title = newValue;
				} else if (newValue instanceof HtmlTemplateResult) {
					newValue.placeholders.update(newValue);
					placeholder.updateNodes(newValue.clone, componentContext);
				} else if (Array.isArray(newValue)) {
					const frag = [];

					newValue.forEach((value) => {
						if (value instanceof HtmlTemplateResult) {
							value.placeholders.update(value);
							Array.from(value.clone.children).forEach(node => frag.push(node));
						} else {
							const node = value instanceof Node ? value : document.createTextNode(value);
							frag.push(node);
						}
					});

					placeholder.updateNodes(frag, componentContext);
				} else if (isNullOrUndefined(newValue)) {
					placeholder.updateNodes([], componentContext);
				} else if (newValue instanceof Node) {
					placeholder.updateNodes([newValue], componentContext);
				} else {
					const value = typeof newValue !== 'string' ? JSON.stringify(newValue) : newValue;
					placeholder.updateNodes([document.createTextNode(value)], componentContext);
				}
			}
		})()
	}
});
