import WebComponent from '#components/web-component.js';

function handleLoad(e) {
	this.loaded = true;
	delete this.dataset.state;
}

const Img = new WebComponent({
	tagName: 'img',
	mapPropsToAttrs: ['src', 'alt'],
	defaultAttributes: {
		is: 'x-img',
	},
	constructor: function () {
		this.dataset.state = 'loading';
		this.loaded = false;
	},
	connectedCallback: function () {
		this.addEventListener('load', handleLoad);
	},
	render: function () {
		return html``;
	}
});

export default Img;
