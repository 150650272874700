import ViewComponent from '#components/view-component.js';

const Portfolio = new ViewComponent({
	defaultAttributes: {
		is: 'x-page-portfolio'
	},
	render: function () {
		const { projects = [] } = this.props;
		const categories = projects.reduce((acc, { category }) => {
			if (!acc.includes(category)) {
				acc.push(category);
			}

			return acc;
		}, []);

		return html`
		<main>
			<hgroup>
				<h1>Portfolio</h1>
				<p>A collection of things I've worked on over the years.</p>
			</hgroup>

			${categories.map(category => {
				return html`<section class="project-list">
					<h2>${category}</h2>
					${projects.filter(project => project.category === category).map(project => {
						const { name, url_slug, thumbnail } = project;
						const src = thumbnail ? thumbnail : `/images/${url_slug}/thumbnail.jpg`;

						return html`<article class="project">
							<h3>${name}</h3>
							<img src="${src}" alt="">
							<a href="/portfolio/${url_slug}">More Details</a>
						</article>`
					})}
				</section>`;
			})}
		</main>`;
	}
});

export default Portfolio;
