import View from '#components/view-component.js';
import WebComponent, { CONNECTED_COMPONENTS } from '#components/web-component.js';
import { INITIAL_STATE } from '#helpers/state.js';

const VIEW_SELECTOR = View.viewSelector;

function getUrlWithoutHash(url) {
	return url.toString().split('#')[0];
}

function BackForwardCache(app) {
	// TODO: enable service worker caching?
	this.cache = new Map();
	this.app = app;

	this.init();

	return this;
}

Object.defineProperties(BackForwardCache.prototype, {
	get: {
		value: function (url, historyType = 'push') {
			const cachedDoc = this.cache.get(url);
			if (cachedDoc) {
				const { doc, href, metadata } = cachedDoc;
				this.set(doc, { href, metadata }, historyType);
				return;
			}

			// set loading state
			this.app.props.isContentLoading = true;

			const instance = this;
			const xhr = new XMLHttpRequest();

			xhr.onerror = function () {
				console.error('Error sending %s request to %s.', 'GET', url);
			};

			xhr.onload = function () {
				const newComponents = this.response.getElementById('component-state');
				const oldComponents = document.getElementById('component-state');

				if (components) {
					window.components = JSON.parse(newComponents.textContent);
					oldComponents.textContent = newComponents.textContent;
				}

				// TODO: get "new" app component props more elegantly
				const metadata = window.components[`x-app-${this.response.documentElement.dataset.uniqueId}`].metadata;
				const doc = this.response.body.querySelector(VIEW_SELECTOR);
				const state = { href: this.responseURL, metadata, doc };

				if (doc.dataset.viewCacheLevel !== 'NONE') {
					instance.cache.set(this.responseURL, state);
				}

				instance.set(doc, { href: this.responseURL, metadata }, historyType);

				instance.app.props.isContentLoading = false;
			}

			xhr.open('GET', url);

			xhr.timeout = 5000;
			xhr.ontimeout;
			xhr.onprogress;

			xhr.responseType = 'document';
			xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
			xhr.send();
		}
	},
	set: {
		value: function (doc, state, historyType) {
			const { href } = state;
			let isLocationChange = true;

			switch (historyType) {
				case 'inert':
					isLocationChange = false;
					break;
				case 'replace':
					isLocationChange = false;
					history.replaceState(state, '', href);
					break;
				case 'push':
					history.pushState(state, '', href);
					break;
				case 'pop':
				default:
					break;
			}

			if (isLocationChange) {
				this.app.content = [doc];
				this.app.props.metadata = state.metadata;
				this.app.props.pageUrl = window.location.pathname;
				document.documentElement.scrollTo(0, 0);
			}
		}
	},
	pop: {
		value: function (e) {
			const { state } = e;

			if (!state) {
				console.warn('No state set for popstate');
				return;
			}

			this.get(state.href, 'pop');
		}
	},
	anchorClick: {
		value: async function (e) {
			const target = e.target.closest('a:link:not([target="_blank"]');

			if (!target || window.location.origin !== target.origin) {
				return;
			}

			const isSameLocation = getUrlWithoutHash(location) === getUrlWithoutHash(target);

			// prevent change
			if (isSameLocation) {
				const { hash } = target;

				if (hash) {
					e.preventDefault()
					const el = document.getElementById(hash.substring(1));
					if (el) {
						el.tabIndex = -1;
						el.scrollIntoView()
						el.focus();
						el.removeAttribute('tabindex');
					}
					return;
				} else {
					e.preventDefault();
					return;
				}
			}

			e.preventDefault();
			e.stopImmediatePropagation();

			this.get(target.href);
		}
	},
	init: {
		value: function () {
			const { href } = window.location;
			const doc = document.querySelector(VIEW_SELECTOR);
			const metadata = this.app.originalProps.metadata;

			if (doc?.dataset.viewCacheLevel !== 'NONE') {
				this.cache.set(href, { href, doc, metadata });
			}

			// Chrome (prior to v34) and Safari always emit a popstate event on page load, but Firefox doesn't: https://developer.mozilla.org/en-US/docs/Web/API/Window/popstate_event
			this.set(doc, { href, metadata }, 'replace');

			document.addEventListener('click', this.anchorClick.bind(this));
			window.addEventListener('popstate', this.pop.bind(this));
		}
	}
});

export default BackForwardCache;
