import ViewComponent from '#components/view-component.js';

const Home = new ViewComponent({
	defaultAttributes: {
		is: 'x-view-home'
	},
	render: function () {
		const { news = [], instagramData = [] } = this.props;

		return html`
		<main>
			<hgroup>
				<h1>Home</h1>
				<p>News, Updates, Etc.</p>
			</hgroup>

			${news.map(article => {
				const { dateCreated } = article;
				const datetime = dateCreated instanceof Date ? dateCreated : new Date(dateCreated);

				return html`<article>
					<hgroup>
						<h2>${article.heading}</h2>
						<p><time datetime="${datetime.toISOString()}">${datetime.toLocaleDateString('en-US', { month: 'long', date: 'numeric', year: 'numeric' })}</time></p>
					</hgroup>

					${article.content}
				</article>`
			})}
		</main>

		<aside>
			<section>
				<h2>Jams</h2>
				<div class="audio-container">
					<iframe src="https://bandcamp.com/EmbeddedPlayer/album=2053007079/size=large/bgcol=333333/linkcol=ffffff/minimal=true/track=1587064988/transparent=true/" seamless lazy></iframe>
				</div>
			</section>

			<!--
			<section>
				<h2>'Grams</h2>
				<ul class="images">
					${instagramData.map(media => html`<li><a href="${media.media_url}" data-dialog="modal"><img src="${media.media_url}" alt="${media.caption}" loading="lazy"></a></li>`)}
				</ul>
			</section>
		-->
		</aside>`;
	}
});

export default Home;
