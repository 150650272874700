export function isNullOrUndefined(value) {
	return value === undefined || value === null;
}

export function encodeDoubleQuotes(string) {
	if (typeof string !== 'string') {
		throw new TypeError('Parameter 1 is not of type string.');
	}

	return string.replace(/"/g, '&quot;');
}

export const isServerSide = new Function('return typeof global === \'object\' && global === this')();

export function debounce(callback, wait, immediate) {
	let timeoutId;

	return function () {
		const context = this;
		const args = arguments;

		const later = function () {
			timeoutId = null;
			if (!immediate) {
				callback.apply(context, args);
			}
		};

		const callNow = immediate && !timeoutId;

		clearTimeout(timeoutId);

		timeoutId = setTimeout(later, wait);

		if (callNow) {
			callback.apply(context, args);
		}
	};
};

export default function throttle(callback, threshhold, scope) {
	threshhold || (threshhold = 250);

	let last, deferTimer;

	return function () {
		const context = scope || this;
		const now = Date.now();
		const args = arguments;
		const func = function () {
			last = now;
			callback.apply(context, args);
		};

		if (last && now < last + threshhold) {
			// hold on to it
			clearTimeout(deferTimer);
			deferTimer = setTimeout(func, threshhold);
		} else {
			func();
		}
	};
};

function compareEntries(obj1, obj2) {
	const entries1 = obj1.entries();
	const entries2 = obj2.entries();

	for (const [key1, value1] of entries1) {
		const [key2, value2] = entries2.next().value;

		if (key1 !== key2 || value1 !== value2) {
			return false;
		}
	}

	return true;
}

const ARE_EQUAL_DEFAULT_OPTIONS = { compareOrder: false, ignoreCase: false, looseZeroComparison: false };

export function areEqual(obj1, obj2, options = ARE_EQUAL_DEFAULT_OPTIONS) {
	// check for immediate equality or NaN since it doesn't equal itself
	if (obj1 === obj2) {
		return options.looseZeroComparison ? true : obj1 !== 0 || 1 / obj1 === 1 / obj2;
	} else if (obj1 !== obj1 && obj2 !== obj2) {
		return true;
	}

	const type1 = typeof obj1;
	const type2 = typeof obj2;

	if (type1 === 'string' && type2 === 'string' && options.ignoreCase) {
		return obj1.toLocaleLowerCase() === obj2.toLocaleLowerCase();
	} else if (type1 !== type2 || type1 !== 'object' && type2 !== 'object') {
		return false
	}

	if (obj1 instanceof Map && obj2 instanceof Map) {
		let equal = obj1.size === obj2.size;

		if (!equal) {
			return false;
		}

		if (options.compareOrder) {
			equal = compareEntries(obj1, obj2);
		} else {
			equal && obj1.forEach((value, key) => {
				if (!areEqual(value, obj2.get(key), options)) {
					equal = false;
				}
			});
		}

		return equal;
	} else if (obj1 instanceof Set && obj2 instanceof Set) {
		let equal = obj1.size === obj2.size;

		if (!equal) {
			return false;
		}

		if (options.compareOrder) {
			equal = compareEntries(obj1, obj2);
		} else {
			equal && obj1.forEach(value => {
				if (!obj2.has(value)) {
					equal = false;
				}
			});
		}

		return equal;
	} else if (Array.isArray(obj1) && Array.isArray(obj2)) {
		return obj1.length === obj2.length && obj1.every((item, index) => areEqual(item, obj2[index], options));
	} else if (obj1 instanceof Date && obj2 instanceof Date) {
		return obj1.getTime() === obj2.getTime();
	}

	// since typeof null === 'object';
	let equal = obj1 && obj2 && Object.keys(obj1).length === Object.keys(obj2).length;

	if (!equal) {
		return false;
	}

	for (const key in obj1) {
		const value1 = obj1[key];
		const value2 = obj2[key];

		equal = areEqual(value1, value2, options);

		if (!equal) {
			break;
		}
	}

	return equal;
}

export function clone(target) {
	if (target instanceof Map) {
		return new Map(target);
	} else if (target instanceof Set) {
		return new Set(target);
	} else if (target instanceof Date) {
		return new Date(target.getTime());
	} else if (Array.isArray(target)) {
		return [...target];
	} else if (!isNullOrUndefined(target) && typeof target === 'object') {
		const proto = Object.getPrototypeOf(target);
		const newObject = Object.create(proto, Object.getOwnPropertyDescriptors(target));

		// TODO: find better way to ensure props are cloned
		if (target.isWebComponent) {
			newObject.originalProps = target.originalProps;
		}

		return newObject;
	} else {
		return target;
	}
}
