import ViewComponent from '#components/view-component.js';
import Form, { SUBMISSION_STATE } from '#components/form.js';
import FormField from '#components/form-field.js';

const Contact = new ViewComponent({
	defaultAttributes: {
		is: 'x-page-contact',
	},
	render: function () {
		const { sent } = this.props;
		let submissionState;

		if (sent === 'true') {
			submissionState = SUBMISSION_STATE.SUCCESS;
		} else if (sent === 'false') {
			submissionState = SUBMISSION_STATE.ERROR;
		}

		return html`
		<main>
			<hgroup>
				<h1>Contact</h1>
				<p>Feel free to send me a message!</p>
			</hgroup>

			${Form({ method: 'post', submitText: 'Send Message!', resetText: 'Clear!', submissionState, successMessage: 'Your message was sent.', errorMessage: 'There was an error sending your message. Please try again.' },
				FormField({ label: 'Name', name: 'name', required: true, errorMessage: 'Please enter your name', style: '--rotate: .5deg;--rotate-focus: -.5deg;' }),
				FormField({ label: 'Phone', name: 'phone', required: true, errorMessage: 'Please enter your phone number', style: '--rotate: .75deg;--rotate-focus: -.75deg;' }),
				FormField({ label: 'Email', name: 'email', required: true, type: 'email', errorMessage: 'Please enter your email address', style: '--rotate: .5deg;--rotate-focus: -.5deg;' }),
				FormField({ label: 'Message', name: 'message', required: true, as: 'textarea', errorMessage: 'Please enter your message', style: '--rotate: .75deg;--rotate-focus: -.75deg;' }),
				FormField({ label: 'Misc.', name: 'misc', tabindex: -1 })
			)}
		</main>

		<aside>
				<section>
					<h2>Social</h2>
					<ul class="image-list">
						<li><a href="https://www.linkedin.com/in/kevinslodic/"><img src="/images/linkedin.svg" alt="LinkedIn"></a></li>
						<li><a href="https://github.com/kevinslodic"><img src="/images/github.svg" alt="GitHub"></a></li>
						<li><a href="https://www.instagram.com/kevinslodic/"><img src="/images/instagram.svg" alt="Instagram"></a></li>
						<li><a href="https://www.facebook.com/kevinslodic/"><img src="/images/facebook.svg" alt="Facebook"></a></li>
						<li><a href="https://www.youtube.com/channel/UC6rqRw_0-lQ-jEHehowUMNg"><img src="/images/youtube.svg" alt="YouTube"></a></li>
						<li><a href="https://twitter.com/kevinslodic"><img src="/images/twitter.svg" alt="Twitter"></a></li>
					</ul>
				</section>
			</aside>`;
	}
});

export default Contact;
