import WebComponent from '#components/web-component.js';
import { ModalDialogContainer } from '#components/dialog.js';
import { INITIAL_STATE } from '#helpers/state.js';
import BackForwardCache from '#helpers/back-forward-cache.js';

import AppHeader from '#components/layout/header.js';
import AppFooter from '#components/layout/footer.js';

import Dialog from '#components/dialog.js';
import Img from '#components/img.js';

function handleGalleryClick(e) {
	const { target } = e;
	const anchor = target.closest('[data-dialog]')

	if (!anchor) {
		return;
	}

	e.stopPropagation();
	e.preventDefault();

	const { href, textContent = '' } = anchor;

	let content;
	if (anchor.dataset.dialog === 'iframe') {
		content = html`<iframe src="${href}" title="${anchor.dataset.dialogTitle}"></iframe>`;
	} else {
		const src = target.nodeName === 'IMG' ? target.src : href;
		content = html`${Img({ src, alt: textContent })}`;
	}

	this.state.dialogs.push(Dialog({ content, opener: anchor }));
}

const Main = new WebComponent({
	tagName: 'html',
	defaultAttributes: {
		lang: 'en-US',
		is: 'x-app',
	},
	connectedCallback: function () {
		window.bfCache = new BackForwardCache(this);
		this.addEventListener('click', handleGalleryClick, true);
	},
	render: function () {
		const { pageType = 'app', pageUrl = '/', metadata = {}, assets, isContentLoading, isAdmin, layout = 'app' } = this.props;
		const { favicon, styles, scripts, admin } = assets;
		const { dialogs } = this.state;

		const isPortfolio = layout === 'portfolio';
		const Header = (isPortfolio ? PortfolioHeader : AppHeader);
		const Footer = (isPortfolio ? PortfolioFooter : AppFooter);

		const metaElements = metadata.meta?.map(meta => html`<meta ${meta}>`);
		const linkElements = metadata.link?.map(link => html`<link ${link}>`);

		const loadingContent = {};

		if (isContentLoading) {
			loadingContent['aria-busy'] = true;
			loadingContent['aria-live'] = 'assertive';
			loadingContent['aria-label'] = 'Loading New Content';
			loadingContent['data-loading'] = 'Loading';
		}

		const appProps = {};
		if (dialogs.length) {
			appProps.inert = true;
		}

		const components = WebComponent.serialize(this.props.components);

		if (this.props.xhr) {
			return html`
				<head>
					${metaElements}
					<title>${metadata.title}</title>
				</head>
				<body>
					${this.content}
					<script type="application/json" id="component-state">${components}</script>
				</body>`;
		}

		return html`<head>
				<meta charset="utf-8">
				<meta http-equiv="X-UA-Compatible" content="IE=edge">
				<meta name="viewport" content="width=device-width, initial-scale=1.0">
				${metaElements}
				<title>${metadata.title}</title>
				<link rel="icon" type="image/x-icon" href="${favicon}">
				<link rel="preconnect" href="https://fonts.googleapis.com">
				<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
				<link href="https://fonts.googleapis.com/css2?family=Chelsea+Market&family=Londrina+Solid:wght@300;400&display=swap" rel="stylesheet">
				<link rel="stylesheet" href="${styles}">
				${linkElements}
				<script>
					window.addEventListener('error', function (e) {
						if (e.error instanceof SyntaxError) {
							console.error('Browser does not support modern JavaScript.');
						}
					});

					document.documentElement.classList.add('js');
				</script>
				<script src="${scripts}" defer></script>
				${isAdmin ? html`<script src="${admin}" defer></script>` : ''}
			</head>

			<body class="${isPortfolio ? 'portfolio' : undefined}" data-page-type="${pageType}" data-page-url="${pageUrl}" data-dialog-count="${dialogs.length}">
				<div id="app" ${appProps}>
					${Header({ pageUrl })}

					<div class="page-content" id="main" ${loadingContent}>
						${this.content}
					</div>

					${Footer()}
				</div>

				${ModalDialogContainer({})}

				<script type="application/json" id="component-state">${components}</script>
				<script type="application/json" id="global-state">${JSON.stringify(this.state, INITIAL_STATE.replacer)}</script>
			</body>`;
	}
});

export default Main;
