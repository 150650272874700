import WebComponent from '#components/web-component.js';

const Header = new WebComponent({
	tagName: 'header',
	defaultAttributes: {
		is: 'x-portfolio-header'
	},
	render: function () {
		const { pageUrl } = this.props;

		return html`
			<a href="#main" class="skip">Skip to main</a>

			<a href="/">
				<img src="/images/logo.svg" width="283" height="84" alt="Kevin Slodic Logo">
			</a>

			<nav>
				<ul>
					<li><a href="/news" aria-current="${pageUrl.startsWith('/news') ? 'page' : ''}">News</a></li>
					<li><a href="/about" aria-current="${pageUrl.startsWith('/about') ? 'page' : ''}">About</a></li>
					<li><a href="/contact" aria-current="${pageUrl.startsWith('/contact') ? 'page' : ''}">Contact</a></li>
					<li><a href="/portfolio" aria-current="${pageUrl.startsWith('/portfolio') ? 'page' : ''}">Portfolio</a></li>
				</ul>
			</nav>
		`;
	}
});

export default Header;
