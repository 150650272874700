import WebComponent from '#components/web-component.js';

const Footer = new WebComponent({
	tagName: 'footer',
	defaultAttributes: {
		is: 'x-portfolio-footer'
	},
	render: function () {
		return html`
			<small>Copyright &copy; ${new Date().getFullYear()} Kevin Slodic. All Rights Reserved</small>
			<ul>
				<li><a href="https://validator.w3.org/check?uri=referer">HTML</a></li>
				<li><a href="https://jigsaw.w3.org/css-validator/check/referer">CSS</a></li>
			</ul>
		`;
	}
});

export default Footer;
