// TODO: create own vendor build?
import './node_modules/@ungap/custom-elements';

import '#helpers/state.js';

import '#components/views/home.js';
import '#components/views/about.js';
import '#components/views/contact.js';
import '#components/views/portfolio.js';
import '#components/views/project.js';

import '#components/app.js';
