import WebComponent from '#components/web-component.js';

const FOCUSABLE_ELEMENTS = ['button', 'input:not([type="hidden"])', 'select', 'textarea', 'summary']
	.map(function (el) { return el + ':not([disabled])'; })
	.concat(['a[href]', 'iframe', 'object', 'embed', '[tabindex]', '[contenteditable]'])
	.map(function (el) { return el + ':not([hidden]):not([tabindex^="-"])'; }).join(',');

function tryFocus(el, isReverse) {
	const elements = getFocusableElements(el);

	let i = isReverse ? elements.length - 1 : 0;
	for (; isReverse ? i >= 0 : i < elements.length; (isReverse ? --i : ++i)) {
		const element = elements[i];

		try {
			element.focus();
		} catch (ex) {
			// focus failed for whatever reason
		}

		if (document.activeElement === element) {
			return element;
		}
	}
}

function getFocusableElements(el) {
	return el.querySelectorAll(FOCUSABLE_ELEMENTS);
}

const CLOSE_DIALOG_KEY_CODES = new Set(['Esc', 'Escape']);

function close(e) {
  if (CLOSE_DIALOG_KEY_CODES.has(e.key) || (e.type === 'click' && e.target.value ===  'close')) {
    this.state.dialogs.pop();
  }
}

function trapFocus(e) {
  if (e.key !== 'Tab') {
    return;
  }

  const tabDirection = e.shiftKey ? 'BACKWARD' : 'FORWARD';
  const activeElement = document.activeElement;
  const items = getFocusableElements(this);
  const firstItem = items[0];
  const lastItem = items[items.length - 1];

  if (tabDirection === 'FORWARD') {
    if (activeElement === lastItem) {
      firstItem.focus();
      e.preventDefault();
    }
  } else {
    if (activeElement === firstItem) {
      lastItem.focus();
      e.preventDefault();
    }
  }
}

const Dialog = new WebComponent({
	tagName: 'dialog',
	defaultAttributes: {
		is: 'x-dialog'
	},
	constructor: function () {
		this.open = true;
		this.setAttribute('aria-modal', true);
	},
	connectedCallback: function () {
		tryFocus(this);

		this.addEventListener('keydown', trapFocus);
		this.addEventListener('keydown', close);
		this.addEventListener('click', close);
	},
	disconnectedCallback: function () {
		this.removeEventListener('keydown', trapFocus);
		this.removeEventListener('keydown', close);
		this.removeEventListener('click', close);

		// because inert
		setTimeout(() => {
			this.props.opener.focus();
		}, 0);
	},
	render: function () {
		const { heading, copy, content } = this.props;

		if (content) {
			return html`
				${content}
				<button value="close" aria-label="Close">X</button>
			`;
		}

		return html`<h1>${heading}</h1>
		<p>${copy}</p>
		<button name="dialog" value="open">Open</button>
		<button value="close">Close</button>`;
	}
});

export default Dialog;

export const ModalDialogContainer = new WebComponent({
	tagName: 'div',
	defaultAttributes: {
		is: 'x-modal-dialog-container'
	},
	connectedCallback: function () {
		this.addEventListener('click', function (e) {
			if (e.target === this) {
				this.state.dialogs.pop();
			}
		});
	},
	render: function () {
		const { dialogs } = this.state;
		return html`${dialogs}`;
	}
});
