import WebComponent from '#components/web-component.js';

function View(props = {}) {
	const { cacheLevel = View.cacheLevel.FULL, ...webComponentProps } = props;
	const mergedProps = Object.assign({ tagName: 'div' }, webComponentProps);

	if (mergedProps.defaultAttributes) {
		mergedProps.defaultAttributes['data-view-cache-level'] = cacheLevel;
		mergedProps.defaultAttributes.class = 'page-wrapper';
	} else {
		mergedProps.defaultAttributes = { 'data-view-cache-level': cacheLevel, class: 'page-wrapper' };
	}

	return new WebComponent(mergedProps);
}

Object.defineProperties(View, {
	viewSelector: {
		value: '[data-view-cache-level]'
	},
	cacheLevel: {
		value: {
			NONE: 'NONE',
			DOM: 'DOM',
			FULL: 'FULL'
		}
	}
})

export default View;