import { areEqual, isNullOrUndefined, isServerSide, clone } from '#helpers/index.js';
import { createState, StateHistory } from '#helpers/state.js';
import {
	HTML_PART_ATTRIBUTE_SET,
	ATTRIBUTE_NODES,
	FRAGMENT_ELEMENTS,
	FRAGMENT_START_ELEMENT,
	FRAGMENT_END_ELEMENT,
	FRAGMENT_CONTENT_START,
	FRAGMENT_CONTENT_END,
	FRAGMENT_CONTENT_START_ELEMENT,
	FRAGMENT_CONTENT_END_ELEMENT
} from '#helpers/html.js';

function fallbackRender() {
	return html`<p>Component ${this.componentName} is missing its render method.</p>`;
}

const CONSTRUCTORS = new Map();
const REHYDRATED_COMPONENTS = new WeakSet();
const DISCONNECTED_COMPONENTS = new WeakSet();
export const CONNECTED_COMPONENTS = new Set();

const WEB_COMPONENT_DESCRIPTORS = {
	super: {
		value: function ({ uniqueId, props = {}, mapPropsToAttrs = [] }) {
			this.uniqueId = uniqueId || this.generateUniqueId();
			this.clientId = this.generateClientId();

			// const mergedProps = Object.create(defaultProps || {}, Object.getOwnPropertyDescriptors(props));
			const mergedProps = Object.assign({}, this.defaultProps, this.rehydratedProps, props);
			const mergedAttrs = Object.assign({}, this.defaultAttributes, Object.entries(mergedProps).reduce((attrs, [propName, propValue]) => {
				if (mapPropsToAttrs.includes(propName)) {
					attrs[propName] = propValue;
				}

				return attrs;
			}, {}));

			this.props = createState(mergedProps, this, this.clientId);
			this.attrs = mergedAttrs;

			return this;
		}
	},
	generateClientId: {
		value: function (suffix) {
			const sections = [this.componentName, this.uniqueId || this.generateUniqueId()];

			if (!isNullOrUndefined(suffix)) {
				sections.push(suffix);
			}

			return sections.join('-');
		}
	},
	generateUniqueId: {
		value: function () {
			let random;

			// ensure Math.random() !== 0; (0).toString(36) === ''
			while (!random) {
				random = Math.random();
			}

			return random.toString(36).substring(2);
		}
	},
	isWebComponent: {
		value: true
	},
	isEqual: {
		value: function (component) {
			if (!component || !component.isWebComponent) {
				return false;
			}

			return areEqual(this.originalProps, component.originalProps);
		}
	},
	componentName: {
		get: function () {
			return this.defaultAttributes?.is || this.tagName;
		}
	},
	propsToExcludeFromSerialization: {
		value: new Set(['output', 'settings', 'state', '_locals', 'cache', 'req', 'components', 'xhr'])
	},
	toJSON: {
		value: function () {
			// TODO: serialize entire component; e.g. { name, props, content }?
			const props = {};

			for (const key in this.props.toJSON()) {
				if (!this.propsToExcludeFromSerialization.has(key)) {
					props[key] = this.props[key];
				}
			}

			return props;
		}
	}
};

function WebComponent(options) {
	const { defaultProps = {}, defaultAttributes = {}, tagName, constructor, render = fallbackRender, disableRehydration = false, mapPropsToAttrs, ...webComponentOptions } = options;

	const WEB_COMPONENT_TYPE_DESCRIPTORS = {
		defaultProps: {
			value: defaultProps
		},
		defaultAttributes: {
			value: defaultAttributes
		},
		tagName: {
			value: tagName
		},
		disableRehydration: {
			value: disableRehydration
		},
	};

	if (isServerSide) {
		function ServerSideComponent(props = {}, ...content) {
			if (!new.target) {
				return new ServerSideComponent(props, ...content);
			}

			this.super({ props, mapPropsToAttrs });
			constructor.apply(this, arguments);

			// ensure content comes back as HtmlPieces
			this.content = content.length ? html`${content}` : content;

			return this;
		}

		Object.defineProperties(ServerSideComponent.prototype, WEB_COMPONENT_DESCRIPTORS);
		Object.defineProperties(ServerSideComponent.prototype, WEB_COMPONENT_TYPE_DESCRIPTORS);
		Object.defineProperties(ServerSideComponent.prototype, {
			render: {
				value: function (state, components = new Set()) {
					this.state = state;
					components.add(this);

					// convert content into string
					if (this.content?.items?.length) {
						this.content.components = components;
						this.content.state = state;
						this.content.isWebComponent = false;
						this.content = `${FRAGMENT_CONTENT_START}${this.content.toString()}${FRAGMENT_CONTENT_END}`;
					}

					const htmlPieces = render.call(this);
					htmlPieces.components = components;
					htmlPieces.state = state;
					htmlPieces.isWebComponent = !this.disableRehydration;

					const { attrs: defaultAttributes = {}, tagName, uniqueId } = this;
					let attrs = {
						...defaultAttributes,
						'data-unique-id': uniqueId
					};

					return html`<${tagName} ${attrs}>${htmlPieces.toString()}</${tagName}>`;
				}
			}
		});

		return ServerSideComponent;
	}

	// to catch global state accessors
	const GLOBAL_STATE_PROXY = new WeakMap();
	const GLOBAL_STATE_PROXY_HANDLER = {
		get: function (target, prop) {
			// capture state props being referenced when rendering
			if (this.component.isRendering && !this.component.globalSubscriptions.has(prop)) {
				this.component.globalSubscriptions.add(prop);
			}

			// return expected getter
			return target[prop];
		}
	};

	const ORIGINAL_PROPS = new WeakMap();

	const { connectedCallback, disconnectedCallback, observedAttributes, attributeChangedCallback, adoptedCallback, preventRegistration, ...customOptions } = webComponentOptions;
	let elementType = CONSTRUCTORS.get(tagName);

	if (!elementType) {
		elementType = Object.getPrototypeOf(document.createElement(tagName)).constructor;
		CONSTRUCTORS.set(tagName, elementType);
	}

	const RENDER_TIMING = {
		get renderTime() {
			return this.renderEnd - this.renderStart;
		},
		get updateTime() {
			return this.updateEnd - this.updateStart;
		},
		get cycleTime() {
			return this.updateEnd - this.renderStart;
		}
	};

	function AbstractComponent(props, ...content) {
		if (!new.target) {
			return new AbstractComponent(props, ...content);
		}

		const { elementType } = this;

		const el = Reflect.construct(elementType, arguments, this.constructor);
		this.super.call(el, { props, mapPropsToAttrs, uniqueId: el.dataset.uniqueId });
		constructor.apply(el, arguments);

		el.prevValues = [];
		el.renders = new Map();
		el.stateHistory = new StateHistory(el);
		el.globalSubscriptions = new Set();
		el.content = content;
		el.dataset.uniqueId = el.uniqueId;

		// TODO: define isServerSideRender as instance prop vs. prototype?
		// keep track of original props for re-rendering comparison
		if (el.rehydratedProps) {
			el.originalProps = el.rehydratedProps;
		} else {
			el.originalProps = props;
		}

		// set default attributes
		if (typeof el.attrs === 'object') {
			Object.entries(el.attrs).forEach(([attr, value]) => {
				el.setAttribute(attr, value);
			});
		}

		// create an instance method per component
		Object.defineProperty(el, 'render', {
			value: (function () {
				let id;

				return function () {
					this.isRenderQueued = true;

					// cancel currently queued frame since another update occurred
					if (id) {
						cancelAnimationFrame(id);
					}

					id = requestAnimationFrame(() => {
						this.renderImmediate();
						id = null;
					});
				}
			})()
		});

		return el;
	}

	Object.defineProperties(AbstractComponent, {
		observedAttributes: {
			value: observedAttributes
		}
	});

	AbstractComponent.prototype = Object.create(elementType.prototype, WEB_COMPONENT_DESCRIPTORS);
	Object.defineProperties(AbstractComponent.prototype, WEB_COMPONENT_TYPE_DESCRIPTORS);
	Object.defineProperties(AbstractComponent.prototype, {
		constructor: {
			value: AbstractComponent
		},
		elementType: {
			value: elementType
		},
		isServerSideRender: {
			get: function () {
				return !!this.rehydratedProps;
			}
		},
		rehydratedProps: {
			get: function () {
				return window.components[this.clientId];
			}
		},
		originalProps: {
			get: function () {
				return ORIGINAL_PROPS.get(this);
			},
			set: function (value) {
				ORIGINAL_PROPS.set(this, Object.freeze(value));
			}
		},
		state: {
			get: function () {
				let proxy = GLOBAL_STATE_PROXY.get(this);

				if (!proxy) {
					proxy = new Proxy(window.globalState, Object.create(GLOBAL_STATE_PROXY_HANDLER, { component: { value: this } }));
					GLOBAL_STATE_PROXY.set(this, proxy);
				}

				return proxy;
			}
		},
		connectedCallback: {
			value: function () {
				if (!this.disableRehydration && (this.isServerSide || !this.renders.size)) {
					this.rehydrate();
				}

				// trigger a re-render if it was previously in the DOM
				if (DISCONNECTED_COMPONENTS.has(this)) {
					DISCONNECTED_COMPONENTS.delete(this);
					this.renderImmediate();
				}

				CONNECTED_COMPONENTS.add(this);

				if (connectedCallback) {
					connectedCallback.apply(this, arguments);
				}
			}
		},
		disconnectedCallback: {
			value: function () {
				if (CONNECTED_COMPONENTS.has(this)) {
					CONNECTED_COMPONENTS.delete(this);
				}

				DISCONNECTED_COMPONENTS.add(this);

				if (disconnectedCallback) {
					disconnectedCallback.apply(this, arguments);
				}
			}
		},
		attributeChangedCallback: {
			value: function () {
				if (attributeChangedCallback) {
					attributeChangedCallback.apply(this, arguments);
				}
			}
		},
		adoptedCallback: {
			value: function () {
				if (adoptedCallback) {
					adoptedCallback.apply(this, arguments);
				}
			}
		},
		rehydrate: {
			value: function () {
				if (REHYDRATED_COMPONENTS.has(this)) {
					return;
				}

				const { isServerSideRender } = this;
				const results = this.renderImmediate(isServerSideRender);

				if (!isServerSideRender) {
					REHYDRATED_COMPONENTS.add(this)
					return results;
				}

				const w = document.createTreeWalker(this);
				let nodeIndex = -1; // since root node is not part of template
				let hasContentPlaceholder = false;
				let content = [];

				do {
					const attrPlaceholders = results.placeholders.items.filter(({ index, type }) => index === nodeIndex && ATTRIBUTE_NODES.has(type));
					const nodePlaceholder = results.placeholders.items.find(({ index, type }) => index === nodeIndex && !ATTRIBUTE_NODES.has(type));
					const { currentNode } = w;

					if (attrPlaceholders.length > 0) {
						attrPlaceholders.forEach(function (attrPlaceholder, index, arr) {
							if (attrPlaceholder.type === HTML_PART_ATTRIBUTE_SET) {
								attrPlaceholder.part = currentNode;
								attrPlaceholder.ownerElement = currentNode
								return;
							}

							// server-side rendered attr either null or undefined, so add to placeholder list
							if (!currentNode.attributes[attrPlaceholder.part.nodeName]) {
								const attr = document.createAttribute(attrPlaceholder.part.nodeName);
								attrPlaceholder.part = attr;
								attrPlaceholder.ownerElement = currentNode;
							}

							attrPlaceholder.part = currentNode.attributes[attrPlaceholder.part.nodeName];
						});
					}

					// in a slotted position check if following nodes are part of template or dynamic
					if (nodePlaceholder) {
						if (currentNode.nodeName === 'TITLE') {
							nodePlaceholder.part.push(currentNode.firstChild);
							w.lastChild(); // ensure title's next sibling is next
						} else if (currentNode.isEqualNode(FRAGMENT_START_ELEMENT)) {
							nodePlaceholder.part = [];
							nodePlaceholder.fragStart = currentNode;

							while (w.nextSibling()) {
								const { currentNode: fragNode } = w;

								let isFragPlaceholderNode = false;
								for (const placeholderFrag of FRAGMENT_ELEMENTS.values()) {
									if (fragNode.isEqualNode(placeholderFrag)) {
										isFragPlaceholderNode = true;
										break;
									}
								}

								// create content prop
								if (fragNode.isEqualNode(FRAGMENT_CONTENT_START_ELEMENT)) {
									hasContentPlaceholder = true;
									nodePlaceholder.contentFragStart = fragNode;
								}

								if (hasContentPlaceholder && !isFragPlaceholderNode) {
									content.push(fragNode);
								}

								if (fragNode.isEqualNode(FRAGMENT_CONTENT_END_ELEMENT)) {
									hasContentPlaceholder = false;
									nodePlaceholder.contentFragEnd = fragNode;
								}

								if (!isFragPlaceholderNode) {
									nodePlaceholder.part.push(fragNode);
								}

								if (fragNode.isEqualNode(FRAGMENT_END_ELEMENT)) {
									nodePlaceholder.fragEnd = fragNode;
									break;
								}
							}
						}
					}

					++nodeIndex;
				} while (w.nextNode());

				if (content.length) {
					this.content = content;
					// need to "re-render" to ensure content is mapped to prevValues
					this.renderImmediate(true);
				}

				this.placeholders = results.placeholders;
				REHYDRATED_COMPONENTS.add(this);

				return results;
			}
		},
		renderImmediate: {
			value: function (preventUpdate) {
				const timing = Object.create(RENDER_TIMING);

				timing.renderStart = performance.now();
				this.isRendering = true;
				const result = render.apply(this);
				this.isRendering = false;
				timing.renderEnd = performance.now();

				const hasPlaceholders = !!this.placeholders
				if (!hasPlaceholders) {
					// on client-side include template node contents;
					if (!preventUpdate) {
						this.replaceChildren(...result.clone.childNodes);
					}

					this.placeholders = result.placeholders;
				}

				if (!preventUpdate) {
					timing.updateStart =  performance.now();
					this.placeholders.update(result, this);
					timing.updateEnd =  performance.now();
					this.renders.set(Date.now(), timing);

					// update attributes
					if (typeof this.attrs === 'object') {
						Object.entries(this.attrs).forEach(([attr, value]) => {
							this.setAttribute(attr, value);
						});
					}
				}

				// find content to generate markers
				if (!hasPlaceholders && this.content.length > 0) {
					const contentPlaceholder = this.placeholders.items.find(({ type, part }) => type === 'child' && areEqual(part, this.content));

					if (contentPlaceholder) {
						let contentFragStart = FRAGMENT_CONTENT_START_ELEMENT.cloneNode(true);
						let contentFragEnd = FRAGMENT_CONTENT_END_ELEMENT.cloneNode(true);

						contentPlaceholder.contentFragStart = contentFragStart;
						contentPlaceholder.contentFragEnd = contentFragEnd;
						contentPlaceholder.fragStart.after(contentFragStart);
						contentPlaceholder.fragEnd.replaceWith(contentFragEnd, contentPlaceholder.fragEnd);
					}
				}

				// TODO: deep clone?
				this.prevValues = result.values.map(clone);

				this.isRenderQueued = false;

				return result;
			}
		}
	});
	Object.defineProperties(AbstractComponent.prototype, customOptions);

	if (!preventRegistration) {
		if (defaultAttributes?.is) {
			if (!customElements.get(defaultAttributes?.is)) {
				customElements.define(defaultAttributes?.is, AbstractComponent, { extends: tagName });
			}
		} else {
			if (!customElements.get(tagName)) {
				customElements.define(tagName, AbstractComponent);
			}
		}
	}

	return AbstractComponent;
}

Object.defineProperties(WebComponent, {
	serialize: {
		value: function (components = []) {
			// to lazy compute for SSR
			return function () {
				const componentList = Array.from(components).reduce((acc, component) => {
					if (!component.disableRehydration) {
						acc[component.clientId] = component;
					}

					return acc;
				}, {});

				return JSON.stringify(componentList);
			}
		}
	}
});

export default WebComponent;