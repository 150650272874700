import WebComponent from '#components/web-component.js';

const CHECKABLE_ELEMENTS = new Set(['checkbox', 'radio']);

function renderOption({ label, value = '', options, ...attributes }) {
	return html`<option value="${value}" ${attributes}>${label}</option>`;
}

const NON_REFLECTIVE_ATTRS = ['selected', 'checked'];
const NON_REFLECTIVE_ATTRS_SELECTOR = NON_REFLECTIVE_ATTRS.map(attr => `[${attr}]`).join(',');

const FormField = new WebComponent({
	tagName: 'div',
	defaultAttributes: {
		is: 'x-form-field',
		class: 'form-field'
	},
	defaultProps: {
		as: 'input'
	},
	connectedCallback: function () {
		// TODO: find better way to apply dynamic root-level attrs
		if (this.props.label === 'Misc.') {
			this.classList.add('visually-hidden');
		}

		// check for non-reflective attributes; e.g. SSR returns <option value="1" selected>, but CSR option.selected does not set HTML attribute
		this.querySelectorAll(NON_REFLECTIVE_ATTRS_SELECTOR).forEach(el => {
			NON_REFLECTIVE_ATTRS.forEach(attr => {
				if (el.hasAttribute(attr)) {
					el.removeAttribute(attr);
					el[attr] = true;
				}
			});
		});
	},
	render: function () {
		const { as, label, errorMessage, tipMessage, options = [], ...attributes } = this.props;
		const labelClass = attributes.required ? 'required' : undefined;
		let field;

		attributes.id = attributes.id || this.clientId;

		const tipHtml = tipMessage && html`<small class="tip-message">${tipMessage}</small>`;
		const errorHtml = errorMessage && html`<small class="error-message">${errorMessage}</small>`

		if (CHECKABLE_ELEMENTS.has(attributes.type)) {
			return html`
				<input ${attributes}>
				<label for="${attributes.id}" class="${labelClass}">${label}</label>
				${tipHtml}
				${errorHtml}
			`;
		} else if (as === 'textarea') {
			field = html`<textarea ${attributes}>${attributes.value}</textarea>`;
		} else if (as === 'select') {
			field = html`<select ${attributes}>
					${options.map(option => Array.isArray(option.optgroup) ? html`<optgroup label="${option.label}">${option.optgroup.map(renderOption)}</optgroup>` : renderOption(option))}
				</select>`;
		} else if (as === 'fieldset') {
			return html`<fieldset ${attributes}>
				<legend>${label}</legend>
				${this.content}
			</fieldset>`
		} else {
			field = html`<input ${attributes}>`;
		}

		return html`
			<label for="${attributes.id}" class="${labelClass}">${label}</label>
			${field}
			${tipHtml}
			${errorHtml}
		`;
	}
});

export default FormField;
