import WebComponent from '#components/web-component.js';

const Message = new WebComponent({
	tagName: 'div',
	mapPropsToAttrs: ['type'],
	defaultAttributes: {
		is: 'x-message',
	},
	render: function () {
		return html`${this.props.message}`;
	}
});

export default Message;
