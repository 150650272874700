import ViewComponent from '#components/view-component.js';

function updateImage(e) {
	const target = e.target.closest('a');
	if (target.href) {
		e.preventDefault();
		e.stopPropagation();
		const mainImage = document.getElementById('main-image');
		mainImage.src = target.href;
	}
}

const Portfolio = new ViewComponent({
	defaultAttributes: {
		is: 'x-page-project'
	},
	connectedCallback: function () {
		const gallery = this.querySelector('.gallery');
		gallery.addEventListener('click', updateImage);
	},
	disconnectedCallback: function () {
		const gallery = this.querySelector('.gallery');
		gallery.removeEventListener('click', updateImage);
	},
	render: function () {
		const { project } = this.props;
		const { name, description, url_slug, url, archived_url, thumbnail, tools = [], images = [], date_completed } = project;
		const dateCompleted = new Date(date_completed);
		const isArchived = !!archived_url;

		return html`
		<main>
			<hgroup>
				<h1>${name}</h1>
				<p><time>${dateCompleted.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</time></p>
			</hgroup>

			<ul class="tools">
					${tools.map(tool => html`<li>${tool}</li>`)}
			</ul>

			${description}

			<p>${isArchived ? `The work I did may no longer be visible in production, but you can <a href="${archived_url}" target="_blank">view an archived version</a> that showcases my work.` : `<a href="${url}" target="_blank">View this project</a> to see my work in action.`}</p>

			<div class="project-images">
				<figure>
					<img src="${images[0]}" id="main-image" alt="">
				</figure>

				<ul class="gallery">
					${images.map(image => html`<li><a href="${image}"><img src="${image}" alt=""></a></li>`)}
				</ul>
			</div>
		</main>`;
	}
});

export default Portfolio;
