import WebComponent from '#components/web-component.js';

const Abbr = new WebComponent({
	tagName: 'abbr',
	mapPropsToAttrs: ['title', 'aria-label'],
	defaultAttributes: {
		is: 'x-abbr',
	},
	constructor: function () {
		const { title: value } = this.props;
		Object.defineProperty(this.attrs, 'aria-label', {
			configurable: true,
			enumerable: true,
			writable: true,
			value
		});

		if (this.title) {
			delete this.attrs.title;
			this.removeAttribute('title');
		}
	},
	render: function () {
		return html`${this.props.text}`;
	}
});

export default Abbr;
